import {
  createContext,
  useContext,
  useState,
  useMemo,
  PropsWithChildren,
  useEffect,
  useCallback,
} from 'react';

// A list of query params used by the marketing team
export enum MarketingQueryParam {
  campaignID = 'campaignID',
  // Aviva's campaignID param is case-sensitive, but the marketing team sometimes passes different case
  campaignId = 'campaignId',
  campaignid = 'campaignid',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
}

interface MarketingQueryParamsContext {
  params: { [key in MarketingQueryParam]?: string },
  setParams: (params: { [key in MarketingQueryParam]?: string }) => void,
  appendParamsToUrl: (url: string) => string
}

const MarketingQueryParamsContext = createContext<MarketingQueryParamsContext | undefined>(
  undefined,
);

export const useMarketingQueryParams = () => {
  const context = useContext(MarketingQueryParamsContext);
  if (!context && typeof window !== 'undefined') {
    throw new Error('useMarketingQueryParams must be used within a MarketingQueryParamsProvider');
  }
  return context;
};

// When a user visits a page with marketing query params set, save them in state for later use
// This is primarily used to append ?utm params to the aviva quoter links
export const MarketingQueryParamsProvider = ({ children }: PropsWithChildren) => {
  const [params, setParams] = useState<MarketingQueryParamsContext['params']>({});
  const search = typeof window !== 'undefined' ? window?.location?.search : '';

  useEffect(() => {
    if (!search) return;
    // Get current query params as { key: value } object
    const queryParams = Object.fromEntries(new URLSearchParams(search));
    // Loop through current query params
    const newParams = Object.entries(queryParams)
      // Filter only marketing query params (check if they're in the MarketingQueryParam enum)
      .filter(([key]) => Object.keys(MarketingQueryParam).includes(key))
      // Reduce array to { key: value } object
      .reduce((acc, [_key, value]) => {
        // See note in the MarketingQueryParam interface above
        // When marketing team passes incorrect campaignid, switch it to the correct case
        let key = _key;
        if (
          key === MarketingQueryParam.campaignid
          || key === MarketingQueryParam.campaignId
        ) {
          key = MarketingQueryParam.campaignID;
        }
        return {
          ...acc,
          [key]: value,
        };
      }, {});

    setParams(prevParams => ({ ...prevParams, ...newParams }));
  }, [search]);

  const appendParamsToUrl = useCallback((url: string): string => {
    if (!Object.values(params).length) return url;
    const newUrl = new URL(url);
    Object.entries(params).forEach(([key, value]) => {
      newUrl.searchParams.set(key, value);
    });
    return newUrl.toString();
  }, [params]);

  const value = useMemo(() => ({
    params,
    setParams,
    appendParamsToUrl,
  }), [params, setParams, appendParamsToUrl]);

  return (
    <MarketingQueryParamsContext.Provider value={value}>
      {children}
    </MarketingQueryParamsContext.Provider>
  );
};
